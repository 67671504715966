<template>
  <div class="main-content">
    <div class="layout-px-spacing mb-5">
      <div
        id="navSection"
        data-spy="affix"
        class="nav sidenav d-none d-md-none d-lg-flex"
        v-if="!loadingReport"
      >
        <div class="sidenav-content shadow">
          <div class="widget-content widget-content-area" style="width: 350px">
            <div class="timeline-simple">
              <p class="timeline-title">Feedback</p>

              <div class="timeline-list" style="overflow: scroll; height: 70vh">
                <div
                  class="timeline-post-content"
                  v-for="feed in globalFeedbacks"
                  :key="feed.id"
                >
                  <div class="user-profile">
                    <img :src="feed.user.image" alt="" />
                  </div>
                  <div class="">
                    <h4>{{ feed.user.firstname }} {{ feed.user.lastname }}</h4>
                    <small class="meta-time-date">
                      {{ formatFancyDate(feed.created_at) }}
                    </small>
                    <div class="">
                      <p class="post-text p-0 mr-5">
                        {{ feed.content }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="mt-3 text-center" v-if="loadingFeedback.global">
                  <b-card class="">
                    <b-skeleton animation="wave" width="85%"></b-skeleton>
                    <b-skeleton animation="wave" width="67%"></b-skeleton>
                    <b-skeleton animation="wave" width="70%"></b-skeleton>
                    <b-skeleton animation="wave" width="82%"></b-skeleton>
                    <b-skeleton animation="wave" width="65%"></b-skeleton>
                  </b-card>
                </div>

                <div class="form-group pr-2 mt-5">
                  <label for="">Add New Feedback</label>
                  <Mentionable :keys="['@']" :items="users" offset="3">
                    <textarea
                      class="form-control"
                      rows="3"
                      v-model="newFeedback.global"
                    />
                  </Mentionable>
                </div>

                <div class="col-12 text-center mt-3">
                  <button
                    type="button"
                    @click.prevent="saveFeedback()"
                    class="btn btn-primary"
                    :disabled="loadingFeedback.global"
                  >
                    <b-spinner small v-if="loadingFeedback.global"></b-spinner>
                    <span v-else>
                      Save Feedback <i class="fa fa-paper-plane"></i
                    ></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row layout-top-spacing">
        <div
          class="col-12 col-md-12 col-lg-7 offset-lg-1 layout-spacing bg-white p-4 shadow"
        >
          <div class="p-2 text-center" v-if="loadingReport">
            <b-card class="">
              <b-skeleton animation="wave" width="85%"></b-skeleton>
              <b-skeleton animation="wave" width="67%"></b-skeleton>
              <b-skeleton animation="wave" width="70%"></b-skeleton>
              <b-skeleton animation="wave" width="82%"></b-skeleton>
              <b-skeleton animation="wave" width="65%"></b-skeleton>
              <b-skeleton animation="wave" width="90%"></b-skeleton>
              <b-skeleton animation="wave" width="95%"></b-skeleton>
              <b-skeleton type="button"></b-skeleton>
            </b-card>
          </div>

          <form v-else>
            <div class="row mb-4">
              <div class="col-12 text-center">
                <p>
                  <b-spinner
                    v-if="loadingReport"
                    variant="light"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                  <span v-else>{{ score }} pts</span>
                </p>
              </div>

              <div class="col-12 col-md-6 mb-3">
                <div class="form-group">
                  <label for="Name">Name</label>
                  <input
                    type="text"
                    :value="`${report.user.firstname} ${report.user.lastname}`"
                    id="Name"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-12 col-md-6 mb-3">
                <div class="form-group">
                  <label for="dept">Department</label>
                  <input
                    type="text"
                    :value="`${report.user.role.toUpperCase()}`"
                    id="dept"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-12 mb-5">
                <div>
                  <p class="font-weight">Routine Tasks</p>
                  <p style="color: #acb0c3">
                    Routine tasks you carry out every week according to your job
                    description. Minimum of 3 and Max of 5. For tech these are
                    tasks you have been instructed to carry out by Management. -
                    50 Points <span class="text-danger">*</span>
                  </p>
                </div>

                <div>
                  <div
                    class="input-group mb-4"
                    v-for="(input, index) in routineTasks"
                    :key="`routineTask-${index}`"
                  >
                    <textarea
                      type="text"
                      class="form-control"
                      readonly
                      v-model="routineTasks[index].description"
                    ></textarea>
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <label
                          class="switch s-primary mb-0"
                          :class="{
                            's-primary': routineTasks[index].completed,
                            's-danger': !routineTasks[index].completed,
                          }"
                        >
                          <input
                            type="checkbox"
                            disabled
                            v-model="routineTasks[index].completed"
                          />
                          <span class="slider round mb-0"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <b-button
                  v-b-toggle.routine-feedback
                  variant="outline-success"
                  class="btn-xs btn-block"
                >
                  Feedback ({{ routineFeedbacks.length }})
                </b-button>
                <b-collapse id="routine-feedback" class="mt-2">
                  <b-card class="border-success">
                    <div
                      class="widget-content widget-content-area"
                      style="width: 100%"
                    >
                      <div class="timeline-simple">
                        <p class="timeline-title">Feedback</p>

                        <div class="timeline-list">
                          <div
                            class="timeline-post-content"
                            v-for="feed in routineFeedbacks"
                            :key="feed.id"
                          >
                            <div class="user-profile">
                              <img :src="feed.user.image" alt="" />
                            </div>
                            <div class="">
                              <h4>
                                {{ feed.user.firstname }}
                                {{ feed.user.lastname }}
                              </h4>
                              <small class="meta-time-date">
                                {{ formatFancyDate(feed.created_at) }}
                              </small>
                              <div class="">
                                <p class="post-text p-0 mr-5">
                                  {{ feed.content }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="mt-3 text-center"
                            v-if="loadingFeedback.routine"
                          >
                            <b-card class="">
                              <b-skeleton
                                animation="wave"
                                width="85%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="67%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="70%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="82%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="65%"
                              ></b-skeleton>
                            </b-card>
                          </div>

                          <div
                            class="form-group pr-2 mt-5"
                            v-if="userWasMentioned('routine')"
                          >
                            <label for="">Add New Feedback</label>
                            <Mentionable
                              :keys="['@']"
                              :items="users"
                              offset="3"
                            >
                              <textarea
                                class="form-control"
                                rows="3"
                                v-model="newFeedback.routine"
                              />
                            </Mentionable>
                          </div>

                          <div
                            class="col-12 text-center mt-3"
                            v-if="userWasMentioned('routine')"
                          >
                            <button
                              type="button"
                              @click.prevent="saveFeedback('routine')"
                              class="btn btn-primary"
                              :disabled="loadingFeedback.routine"
                            >
                              <b-spinner
                                small
                                v-if="loadingFeedback.routine"
                              ></b-spinner>
                              <span v-else>
                                Save Feedback <i class="fa fa-paper-plane"></i
                              ></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card>
                </b-collapse>
              </div>

              <div class="col-12 mb-5">
                <div>
                  <p class="font-weight">Outstanding Tasks</p>
                  <p style="color: #acb0c3">
                    Outstanding Tasks from the previous week - Completion of
                    outstanding tasks from the previous week - 20 Points<span
                      class="text-danger"
                      >*</span
                    >
                  </p>
                </div>

                <div>
                  <div
                    class="input-group mb-4"
                    v-for="(input, index) in outstandingTasks"
                    :key="`outstandingTask-${index}`"
                  >
                    <textarea
                      type="text"
                      class="form-control"
                      readonly
                      v-model="outstandingTasks[index].description"
                    ></textarea>
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <label
                          class="switch s-primary mb-0"
                          :class="{
                            's-primary': outstandingTasks[index].completed,
                            's-danger': !outstandingTasks[index].completed,
                          }"
                        >
                          <input
                            type="checkbox"
                            disabled
                            v-model="outstandingTasks[index].completed"
                          />
                          <span class="slider round mb-0"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <b-button
                  v-b-toggle.outstanding-feedback
                  variant="outline-success"
                  class="btn-xs btn-block"
                >
                  Feedback ({{ outstandingFeedbacks.length }})
                </b-button>
                <b-collapse id="outstanding-feedback" class="mt-2">
                  <b-card class="border-success">
                    <div
                      class="widget-content widget-content-area"
                      style="width: 100%"
                    >
                      <div class="timeline-simple">
                        <p class="timeline-title">Feedback</p>

                        <div class="timeline-list">
                          <div
                            class="timeline-post-content"
                            v-for="feed in outstandingFeedbacks"
                            :key="feed.id"
                          >
                            <div class="user-profile">
                              <img :src="feed.user.image" alt="" />
                            </div>
                            <div class="">
                              <h4>
                                {{ feed.user.firstname }}
                                {{ feed.user.lastname }}
                              </h4>
                              <small class="meta-time-date">
                                {{ formatFancyDate(feed.created_at) }}
                              </small>
                              <div class="">
                                <p class="post-text p-0 mr-5">
                                  {{ feed.content }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="mt-3 text-center"
                            v-if="loadingFeedback.outstanding"
                          >
                            <b-card class="">
                              <b-skeleton
                                animation="wave"
                                width="85%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="67%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="70%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="82%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="65%"
                              ></b-skeleton>
                            </b-card>
                          </div>

                          <div
                            class="form-group pr-2 mt-5"
                            v-if="userWasMentioned('outstanding')"
                          >
                            <label for="">Add New Feedback</label>
                            <Mentionable
                              :keys="['@']"
                              :items="users"
                              offset="3"
                            >
                              <textarea
                                class="form-control"
                                rows="3"
                                v-model="newFeedback.outstanding"
                              />
                            </Mentionable>
                          </div>

                          <div
                            class="col-12 text-center mt-3"
                            v-if="userWasMentioned('outstanding')"
                          >
                            <button
                              type="button"
                              @click.prevent="saveFeedback('outstanding')"
                              class="btn btn-primary"
                              :disabled="loadingFeedback.outstanding"
                            >
                              <b-spinner
                                small
                                v-if="loadingFeedback.outstanding"
                              ></b-spinner>
                              <span v-else>
                                Save Feedback <i class="fa fa-paper-plane"></i
                              ></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card>
                </b-collapse>
              </div>

              <div class="col-12 mb-5">
                <div>
                  <p class="font-weight">New Tasks</p>
                  <p style="color: #acb0c3">
                    Tasks given to you during the week that may or may not be
                    directly related to your job description - 20 Points
                    <span class="text-danger">*</span>
                  </p>
                </div>

                <div>
                  <div
                    class="input-group mb-4"
                    v-for="(input, index) in newTasks"
                    :key="`newTask-${index}`"
                  >
                    <textarea
                      type="text"
                      class="form-control"
                      readonly
                      v-model="newTasks[index].description"
                    ></textarea>
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <label
                          class="switch s-primary mb-0"
                          :class="{
                            's-primary': newTasks[index].completed,
                            's-danger': !newTasks[index].completed,
                          }"
                        >
                          <input
                            type="checkbox"
                            disabled
                            v-model="newTasks[index].completed"
                          />
                          <span class="slider round mb-0"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <b-button
                  v-b-toggle.new-feedback
                  variant="outline-success"
                  class="btn-xs btn-block"
                >
                  Feedback ({{ newFeedbacks.length }})
                </b-button>
                <b-collapse id="new-feedback" class="mt-2">
                  <b-card class="border-success">
                    <div
                      class="widget-content widget-content-area"
                      style="width: 100%"
                    >
                      <div class="timeline-simple">
                        <p class="timeline-title">Feedback</p>

                        <div class="timeline-list">
                          <div
                            class="timeline-post-content"
                            v-for="feed in newFeedbacks"
                            :key="feed.id"
                          >
                            <div class="user-profile">
                              <img :src="feed.user.image" alt="" />
                            </div>
                            <div class="">
                              <h4>
                                {{ feed.user.firstname }}
                                {{ feed.user.lastname }}
                              </h4>
                              <small class="meta-time-date">
                                {{ formatFancyDate(feed.created_at) }}
                              </small>
                              <div class="">
                                <p class="post-text p-0 mr-5">
                                  {{ feed.content }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="mt-3 text-center"
                            v-if="loadingFeedback.new"
                          >
                            <b-card class="">
                              <b-skeleton
                                animation="wave"
                                width="85%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="67%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="70%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="82%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="65%"
                              ></b-skeleton>
                            </b-card>
                          </div>

                          <div
                            class="form-group pr-2 mt-5"
                            v-if="userWasMentioned('new')"
                          >
                            <label for="">Add New Feedback</label>
                            <Mentionable
                              :keys="['@']"
                              :items="users"
                              offset="3"
                            >
                              <textarea
                                class="form-control"
                                rows="3"
                                v-model="newFeedback.new"
                              />
                            </Mentionable>
                          </div>

                          <div
                            class="col-12 text-center mt-3"
                            v-if="userWasMentioned('new')"
                          >
                            <button
                              type="button"
                              @click.prevent="saveFeedback('new')"
                              class="btn btn-primary"
                              :disabled="loadingFeedback.new"
                            >
                              <b-spinner
                                small
                                v-if="loadingFeedback.new"
                              ></b-spinner>
                              <span v-else>
                                Save Feedback <i class="fa fa-paper-plane"></i
                              ></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card>
                </b-collapse>
              </div>

              <div class="col-12 mb-5">
                <div>
                  <p class="font-weight">Any Other Tasks</p>
                  <p style="color: #acb0c3">
                    Pro active tasks you give yourself during the week that move
                    GeroCare forward. - 10 Points
                    <span class="text-danger">*</span>
                  </p>
                </div>

                <div>
                  <div
                    class="input-group mb-4"
                    v-for="(input, index) in otherTasks"
                    :key="`otherTask-${index}`"
                  >
                    <textarea
                      type="text"
                      class="form-control"
                      readonly
                      v-model="otherTasks[index].description"
                    ></textarea>
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <label
                          class="switch s-primary mb-0"
                          :class="{
                            's-primary': otherTasks[index].completed,
                            's-danger': !otherTasks[index].completed,
                          }"
                        >
                          <input
                            type="checkbox"
                            disabled
                            v-model="otherTasks[index].completed"
                          />
                          <span class="slider round mb-0"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <b-button
                  v-b-toggle.other-feedback
                  variant="outline-success"
                  class="btn-xs btn-block"
                >
                  Feedback ({{ otherFeedbacks.length }})
                </b-button>
                <b-collapse id="other-feedback" class="mt-2">
                  <b-card class="border-success">
                    <div
                      class="widget-content widget-content-area"
                      style="width: 100%"
                    >
                      <div class="timeline-simple">
                        <p class="timeline-title">Feedback</p>

                        <div class="timeline-list">
                          <div
                            class="timeline-post-content"
                            v-for="feed in otherFeedbacks"
                            :key="feed.id"
                          >
                            <div class="user-profile">
                              <img :src="feed.user.image" alt="" />
                            </div>
                            <div class="">
                              <h4>
                                {{ feed.user.firstname }}
                                {{ feed.user.lastname }}
                              </h4>
                              <small class="meta-time-date">
                                {{ formatFancyDate(feed.created_at) }}
                              </small>
                              <div class="">
                                <p class="post-text p-0 mr-5">
                                  {{ feed.content }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="mt-3 text-center"
                            v-if="loadingFeedback.other"
                          >
                            <b-card class="">
                              <b-skeleton
                                animation="wave"
                                width="85%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="67%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="70%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="82%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="65%"
                              ></b-skeleton>
                            </b-card>
                          </div>

                          <div
                            class="form-group pr-2 mt-5"
                            v-if="userWasMentioned('other')"
                          >
                            <label for="">Add New Feedback</label>
                            <Mentionable
                              :keys="['@']"
                              :items="users"
                              offset="3"
                            >
                              <textarea
                                class="form-control"
                                rows="3"
                                v-model="newFeedback.other"
                              />
                            </Mentionable>
                          </div>

                          <div
                            class="col-12 text-center mt-3"
                            v-if="userWasMentioned('other')"
                          >
                            <button
                              type="button"
                              @click.prevent="saveFeedback('other')"
                              class="btn btn-primary"
                              :disabled="loadingFeedback.other"
                            >
                              <b-spinner
                                small
                                v-if="loadingFeedback.other"
                              ></b-spinner>
                              <span v-else>
                                Save Feedback <i class="fa fa-paper-plane"></i
                              ></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card>
                </b-collapse>
              </div>

              <div class="col-12 mb-5">
                <div class="form-group">
                  <label class="text-dark" for="didntGoWell"
                    >What did not go well during the previous week and
                    why?</label
                  >
                  <textarea
                    class="form-control"
                    v-model="report.didntGoWell"
                    id="didntGoWell"
                    readonly
                    rows="4"
                  ></textarea>
                </div>

                <b-button
                  v-b-toggle.didnotgowell-feedback
                  variant="outline-success"
                  class="btn-xs btn-block"
                >
                  Feedback ({{ didnotgowellFeedbacks.length }})
                </b-button>
                <b-collapse id="didnotgowell-feedback" class="mt-2">
                  <b-card class="border-success">
                    <div
                      class="widget-content widget-content-area"
                      style="width: 100%"
                    >
                      <div class="timeline-simple">
                        <p class="timeline-title">Feedback</p>

                        <div class="timeline-list">
                          <div
                            class="timeline-post-content"
                            v-for="feed in didnotgowellFeedbacks"
                            :key="feed.id"
                          >
                            <div class="user-profile">
                              <img :src="feed.user.image" alt="" />
                            </div>
                            <div class="">
                              <h4>
                                {{ feed.user.firstname }}
                                {{ feed.user.lastname }}
                              </h4>
                              <small class="meta-time-date">
                                {{ formatFancyDate(feed.created_at) }}
                              </small>
                              <div class="">
                                <p class="post-text p-0 mr-5">
                                  {{ feed.content }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="mt-3 text-center"
                            v-if="loadingFeedback.didnotgowell"
                          >
                            <b-card class="">
                              <b-skeleton
                                animation="wave"
                                width="85%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="67%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="70%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="82%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="65%"
                              ></b-skeleton>
                            </b-card>
                          </div>

                          <div
                            class="form-group pr-2 mt-5"
                            v-if="userWasMentioned('didnotgowell')"
                          >
                            <label for="">Add New Feedback</label>
                            <Mentionable
                              :keys="['@']"
                              :items="users"
                              offset="3"
                            >
                              <textarea
                                class="form-control"
                                rows="3"
                                v-model="newFeedback.didnotgowell"
                              />
                            </Mentionable>
                          </div>

                          <div
                            class="col-12 text-center mt-3"
                            v-if="userWasMentioned('didnotgowell')"
                          >
                            <button
                              type="button"
                              @click.prevent="saveFeedback('didnotgowell')"
                              class="btn btn-primary"
                              :disabled="loadingFeedback.didnotgowell"
                            >
                              <b-spinner
                                small
                                v-if="loadingFeedback.didnotgowell"
                              ></b-spinner>
                              <span v-else>
                                Save Feedback <i class="fa fa-paper-plane"></i
                              ></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card>
                </b-collapse>
              </div>

              <div class="col-12 mb-5">
                <div class="form-group">
                  <label class="text-dark" for="shouldKnowAbout"
                    >Anything that happened during the previous week we should
                    know about?</label
                  >
                  <textarea
                    class="form-control"
                    v-model="report.shouldKnowAbout"
                    id="shouldKnowAbout"
                    readonly
                    rows="4"
                  ></textarea>
                </div>

                <b-button
                  v-b-toggle.prevweek-feedback
                  variant="outline-success"
                  class="btn-xs btn-block"
                >
                  Feedback ({{ prevweekFeedbacks.length }})
                </b-button>
                <b-collapse id="prevweek-feedback" class="mt-2">
                  <b-card class="border-success">
                    <div
                      class="widget-content widget-content-area"
                      style="width: 100%"
                    >
                      <div class="timeline-simple">
                        <p class="timeline-title">Feedback</p>

                        <div class="timeline-list">
                          <div
                            class="timeline-post-content"
                            v-for="feed in prevweekFeedbacks"
                            :key="feed.id"
                          >
                            <div class="user-profile">
                              <img :src="feed.user.image" alt="" />
                            </div>
                            <div class="">
                              <h4>
                                {{ feed.user.firstname }}
                                {{ feed.user.lastname }}
                              </h4>
                              <small class="meta-time-date">
                                {{ formatFancyDate(feed.created_at) }}
                              </small>
                              <div class="">
                                <p class="post-text p-0 mr-5">
                                  {{ feed.content }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="mt-3 text-center"
                            v-if="loadingFeedback.prevweek"
                          >
                            <b-card class="">
                              <b-skeleton
                                animation="wave"
                                width="85%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="67%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="70%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="82%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="65%"
                              ></b-skeleton>
                            </b-card>
                          </div>

                          <div
                            class="form-group pr-2 mt-5"
                            v-if="userWasMentioned('prevweek')"
                          >
                            <label for="">Add New Feedback</label>
                            <Mentionable
                              :keys="['@']"
                              :items="users"
                              offset="3"
                            >
                              <textarea
                                class="form-control"
                                rows="3"
                                v-model="newFeedback.prevweek"
                              />
                            </Mentionable>
                          </div>

                          <div
                            class="col-12 text-center mt-3"
                            v-if="userWasMentioned('prevweek')"
                          >
                            <button
                              type="button"
                              @click.prevent="saveFeedback('prevweek')"
                              class="btn btn-primary"
                              :disabled="loadingFeedback.prevweek"
                            >
                              <b-spinner
                                small
                                v-if="loadingFeedback.prevweek"
                              ></b-spinner>
                              <span v-else>
                                Save Feedback <i class="fa fa-paper-plane"></i
                              ></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card>
                </b-collapse>
              </div>

              <div class="col-12 mb-5">
                <div class="form-group">
                  <label class="text-dark" for="suggestion"
                    >Any suggestion you have for Management or your
                    colleagues?</label
                  >
                  <textarea
                    class="form-control"
                    v-model="report.suggestion"
                    id="suggestion"
                    readonly
                    rows="4"
                  ></textarea>
                </div>

                <b-button
                  v-b-toggle.suggestion-feedback
                  variant="outline-success"
                  class="btn-xs btn-block"
                >
                  Feedback ({{ suggestionFeedbacks.length }})
                </b-button>
                <b-collapse id="suggestion-feedback" class="mt-2">
                  <b-card class="border-success">
                    <div
                      class="widget-content widget-content-area"
                      style="width: 100%"
                    >
                      <div class="timeline-simple">
                        <p class="timeline-title">Feedback</p>

                        <div class="timeline-list">
                          <div
                            class="timeline-post-content"
                            v-for="feed in suggestionFeedbacks"
                            :key="feed.id"
                          >
                            <div class="user-profile">
                              <img :src="feed.user.image" alt="" />
                            </div>
                            <div class="">
                              <h4>
                                {{ feed.user.firstname }}
                                {{ feed.user.lastname }}
                              </h4>
                              <small class="meta-time-date">
                                {{ formatFancyDate(feed.created_at) }}
                              </small>
                              <div class="">
                                <p class="post-text p-0 mr-5">
                                  {{ feed.content }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="mt-3 text-center"
                            v-if="loadingFeedback.suggestion"
                          >
                            <b-card class="">
                              <b-skeleton
                                animation="wave"
                                width="85%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="67%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="70%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="82%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="65%"
                              ></b-skeleton>
                            </b-card>
                          </div>

                          <div
                            class="form-group pr-2 mt-5"
                            v-if="userWasMentioned('suggestion')"
                          >
                            <label for="">Add New Feedback</label>
                            <Mentionable
                              :keys="['@']"
                              :items="users"
                              offset="3"
                            >
                              <textarea
                                class="form-control"
                                rows="3"
                                v-model="newFeedback.suggestion"
                              />
                            </Mentionable>
                          </div>

                          <div
                            class="col-12 text-center mt-3"
                            v-if="userWasMentioned('suggestion')"
                          >
                            <button
                              type="button"
                              @click.prevent="saveFeedback('suggestion')"
                              class="btn btn-primary"
                              :disabled="loadingFeedback.suggestion"
                            >
                              <b-spinner
                                small
                                v-if="loadingFeedback.suggestion"
                              ></b-spinner>
                              <span v-else>
                                Save Feedback <i class="fa fa-paper-plane"></i
                              ></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card>
                </b-collapse>
              </div>

              <!-- outstanding visits -->
              <div class="col-12 mb-5" v-if="report.user.role == 'admin'">
                <div>
                  <p class="font-weight">Review of Outstanding Visits</p>
                </div>
                <div class="form-group">
                  <textarea
                    type="text"
                    readonly
                    v-model="report.outstanding_visit_report"
                    class="form-control"
                    rows="5"
                  ></textarea>
                </div>

                <b-button
                  v-b-toggle.outstandingvisit-feedback
                  variant="outline-success"
                  class="btn-xs btn-block"
                >
                  Feedback ({{ outstandingvisitFeedbacks.length }})
                </b-button>
                <b-collapse id="outstandingvisit-feedback" class="mt-2">
                  <b-card class="border-success">
                    <div
                      class="widget-content widget-content-area"
                      style="width: 100%"
                    >
                      <div class="timeline-simple">
                        <p class="timeline-title">Feedback</p>

                        <div class="timeline-list">
                          <div
                            class="timeline-post-content"
                            v-for="feed in outstandingvisitFeedbacks"
                            :key="feed.id"
                          >
                            <div class="user-profile">
                              <img :src="feed.user.image" alt="" />
                            </div>
                            <div class="">
                              <h4>
                                {{ feed.user.firstname }}
                                {{ feed.user.lastname }}
                              </h4>
                              <small class="meta-time-date">
                                {{ formatFancyDate(feed.created_at) }}
                              </small>
                              <div class="">
                                <p class="post-text p-0 mr-5">
                                  {{ feed.content }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="mt-3 text-center"
                            v-if="loadingFeedback.other"
                          >
                            <b-card class="">
                              <b-skeleton
                                animation="wave"
                                width="85%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="67%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="70%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="82%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="65%"
                              ></b-skeleton>
                            </b-card>
                          </div>

                          <div class="form-group pr-2 mt-5">
                            <label for="">Add New Feedback</label>
                            <Mentionable
                              :keys="['@']"
                              :items="users"
                              offset="3"
                            >
                              <textarea
                                class="form-control"
                                rows="3"
                                v-model="newFeedback.report"
                              />
                            </Mentionable>
                          </div>

                          <div class="col-12 text-center mt-3">
                            <button
                              type="button"
                              @click.prevent="saveFeedback('outstandingvisit')"
                              class="btn btn-primary"
                              :disabled="loadingFeedback.report"
                            >
                              <b-spinner
                                small
                                v-if="loadingFeedback.report"
                              ></b-spinner>
                              <span v-else>
                                Save Feedback <i class="fa fa-paper-plane"></i
                              ></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card>
                </b-collapse>
              </div>

              <!-- payment log -->
              <div class="col-12 mb-5" v-if="report.user.role == 'accounts'">
                <div>
                  <p class="font-weight">Payment Log Report</p>
                </div>
                <div class="form-group">
                  <textarea
                    type="text"
                    readonly
                    v-model="report.payment_log"
                    class="form-control"
                    rows="5"
                  ></textarea>
                </div>

                <b-button
                  v-b-toggle.paymentlog-feedback
                  variant="outline-success"
                  class="btn-xs btn-block"
                >
                  Feedback ({{ paymentlogFeedbacks.length }})
                </b-button>
                <b-collapse id="paymentlog-feedback" class="mt-2">
                  <b-card class="border-success">
                    <div
                      class="widget-content widget-content-area"
                      style="width: 100%"
                    >
                      <div class="timeline-simple">
                        <p class="timeline-title">Feedback</p>

                        <div class="timeline-list">
                          <div
                            class="timeline-post-content"
                            v-for="feed in paymentlogFeedbacks"
                            :key="feed.id"
                          >
                            <div class="user-profile">
                              <img :src="feed.user.image" alt="" />
                            </div>
                            <div class="">
                              <h4>
                                {{ feed.user.firstname }}
                                {{ feed.user.lastname }}
                              </h4>
                              <small class="meta-time-date">
                                {{ formatFancyDate(feed.created_at) }}
                              </small>
                              <div class="">
                                <p class="post-text p-0 mr-5">
                                  {{ feed.content }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="mt-3 text-center"
                            v-if="loadingFeedback.other"
                          >
                            <b-card class="">
                              <b-skeleton
                                animation="wave"
                                width="85%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="67%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="70%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="82%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="65%"
                              ></b-skeleton>
                            </b-card>
                          </div>

                          <div class="form-group pr-2 mt-5">
                            <label for="">Add New Feedback</label>
                            <Mentionable
                              :keys="['@']"
                              :items="users"
                              offset="3"
                            >
                              <textarea
                                class="form-control"
                                rows="3"
                                v-model="newFeedback.report"
                              />
                            </Mentionable>
                          </div>

                          <div class="col-12 text-center mt-3">
                            <button
                              type="button"
                              @click.prevent="saveFeedback('paymentlog')"
                              class="btn btn-primary"
                              :disabled="loadingFeedback.report"
                            >
                              <b-spinner
                                small
                                v-if="loadingFeedback.report"
                              ></b-spinner>
                              <span v-else>
                                Save Feedback <i class="fa fa-paper-plane"></i
                              ></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card>
                </b-collapse>
              </div>

              <!-- corporate/firstvisits -->
              <div
                class="col-12 mb-5"
                v-if="report.user.role == 'medical services'"
              >
                <div>
                  <p class="font-weight">
                    Update on Corporate Visits & First Visits
                  </p>
                </div>

                <div class="form-group">
                  <textarea
                    type="text"
                    readonly
                    v-model="report.corporate_client_update"
                    class="form-control"
                    rows="5"
                  ></textarea>
                </div>

                <b-button
                  v-b-toggle.corporatefirstvisits-feedback
                  variant="outline-success"
                  class="btn-xs btn-block"
                >
                  Feedback ({{ corporatefirstvisitsFeedbacks.length }})
                </b-button>
                <b-collapse id="corporatefirstvisits-feedback" class="mt-2">
                  <b-card class="border-success">
                    <div
                      class="widget-content widget-content-area"
                      style="width: 100%"
                    >
                      <div class="timeline-simple">
                        <p class="timeline-title">Feedback</p>

                        <div class="timeline-list">
                          <div
                            class="timeline-post-content"
                            v-for="feed in corporatefirstvisitsFeedbacks"
                            :key="feed.id"
                          >
                            <div class="user-profile">
                              <img :src="feed.user.image" alt="" />
                            </div>
                            <div class="">
                              <h4>
                                {{ feed.user.firstname }}
                                {{ feed.user.lastname }}
                              </h4>
                              <small class="meta-time-date">
                                {{ formatFancyDate(feed.created_at) }}
                              </small>
                              <div class="">
                                <p class="post-text p-0 mr-5">
                                  {{ feed.content }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="mt-3 text-center"
                            v-if="loadingFeedback.other"
                          >
                            <b-card class="">
                              <b-skeleton
                                animation="wave"
                                width="85%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="67%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="70%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="82%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="65%"
                              ></b-skeleton>
                            </b-card>
                          </div>

                          <div class="form-group pr-2 mt-5">
                            <label for="">Add New Feedback</label>
                            <Mentionable
                              :keys="['@']"
                              :items="users"
                              offset="3"
                            >
                              <textarea
                                class="form-control"
                                rows="3"
                                v-model="newFeedback.report"
                              />
                            </Mentionable>
                          </div>

                          <div class="col-12 text-center mt-3">
                            <button
                              type="button"
                              @click.prevent="
                                saveFeedback('corporatefirstvisits')
                              "
                              class="btn btn-primary"
                              :disabled="loadingFeedback.report"
                            >
                              <b-spinner
                                small
                                v-if="loadingFeedback.report"
                              ></b-spinner>
                              <span v-else>
                                Save Feedback <i class="fa fa-paper-plane"></i
                              ></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card>
                </b-collapse>
              </div>

              <!-- Elderly remark -->
              <div
                class="col-12 mb-5"
                v-if="report.user.role == 'elderly services'"
              >
                <div>
                  <p class="font-weight">
                    Elderly Remarks for Visits in the Previous Week
                  </p>
                </div>

                <div class="form-group">
                  <textarea
                    type="text"
                    readonly
                    v-model="report.elderly_remark"
                    class="form-control"
                    rows="5"
                  ></textarea>
                </div>

                <b-button
                  v-b-toggle.elderlyremark-feedback
                  variant="outline-success"
                  class="btn-xs btn-block"
                >
                  Feedback ({{ elderlyremarkFeedbacks.length }})
                </b-button>
                <b-collapse id="elderlyremark-feedback" class="mt-2">
                  <b-card class="border-success">
                    <div
                      class="widget-content widget-content-area"
                      style="width: 100%"
                    >
                      <div class="timeline-simple">
                        <p class="timeline-title">Feedback</p>

                        <div class="timeline-list">
                          <div
                            class="timeline-post-content"
                            v-for="feed in elderlyremarkFeedbacks"
                            :key="feed.id"
                          >
                            <div class="user-profile">
                              <img :src="feed.user.image" alt="" />
                            </div>
                            <div class="">
                              <h4>
                                {{ feed.user.firstname }}
                                {{ feed.user.lastname }}
                              </h4>
                              <small class="meta-time-date">
                                {{ formatFancyDate(feed.created_at) }}
                              </small>
                              <div class="">
                                <p class="post-text p-0 mr-5">
                                  {{ feed.content }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="mt-3 text-center"
                            v-if="loadingFeedback.other"
                          >
                            <b-card class="">
                              <b-skeleton
                                animation="wave"
                                width="85%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="67%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="70%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="82%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="65%"
                              ></b-skeleton>
                            </b-card>
                          </div>

                          <div class="form-group pr-2 mt-5">
                            <label for="">Add New Feedback</label>
                            <Mentionable
                              :keys="['@']"
                              :items="users"
                              offset="3"
                            >
                              <textarea
                                class="form-control"
                                rows="3"
                                v-model="newFeedback.report"
                              />
                            </Mentionable>
                          </div>

                          <div class="col-12 text-center mt-3">
                            <button
                              type="button"
                              @click.prevent="saveFeedback('elderlyremark')"
                              class="btn btn-primary"
                              :disabled="loadingFeedback.report"
                            >
                              <b-spinner
                                small
                                v-if="loadingFeedback.report"
                              ></b-spinner>
                              <span v-else>
                                Save Feedback <i class="fa fa-paper-plane"></i
                              ></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card>
                </b-collapse>
              </div>

              <!-- Weekly report update -->
              <div
                class="col-12 mb-5"
                v-if="report.user.role == 'client services'"
              >
                <div>
                  <p class="font-weight">Outstanding Payments/Weekly Update</p>
                </div>

                <div class="form-group">
                  <textarea
                    type="text"
                    readonly
                    v-model="report.general_report"
                    class="form-control"
                    rows="5"
                  ></textarea>
                </div>

                <b-button
                  v-b-toggle.weeklyreport-feedback
                  variant="outline-success"
                  class="btn-xs btn-block"
                >
                  Feedback ({{ weeklyreportFeedbacks.length }})
                </b-button>
                <b-collapse id="weeklyreport-feedback" class="mt-2">
                  <b-card class="border-success">
                    <div
                      class="widget-content widget-content-area"
                      style="width: 100%"
                    >
                      <div class="timeline-simple">
                        <p class="timeline-title">Feedback</p>

                        <div class="timeline-list">
                          <div
                            class="timeline-post-content"
                            v-for="feed in weeklyreportFeedbacks"
                            :key="feed.id"
                          >
                            <div class="user-profile">
                              <img :src="feed.user.image" alt="" />
                            </div>
                            <div class="">
                              <h4>
                                {{ feed.user.firstname }}
                                {{ feed.user.lastname }}
                              </h4>
                              <small class="meta-time-date">
                                {{ formatFancyDate(feed.created_at) }}
                              </small>
                              <div class="">
                                <p class="post-text p-0 mr-5">
                                  {{ feed.content }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="mt-3 text-center"
                            v-if="loadingFeedback.other"
                          >
                            <b-card class="">
                              <b-skeleton
                                animation="wave"
                                width="85%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="67%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="70%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="82%"
                              ></b-skeleton>
                              <b-skeleton
                                animation="wave"
                                width="65%"
                              ></b-skeleton>
                            </b-card>
                          </div>

                          <div class="form-group pr-2 mt-5">
                            <label for="">Add New Feedback</label>
                            <Mentionable
                              :keys="['@']"
                              :items="users"
                              offset="3"
                            >
                              <textarea
                                class="form-control"
                                rows="3"
                                v-model="newFeedback.report"
                              />
                            </Mentionable>
                          </div>

                          <div class="col-12 text-center mt-3">
                            <button
                              type="button"
                              @click.prevent="saveFeedback('weeklyreport')"
                              class="btn btn-primary"
                              :disabled="loadingFeedback.report"
                            >
                              <b-spinner
                                small
                                v-if="loadingFeedback.report"
                              ></b-spinner>
                              <span v-else>
                                Save Feedback <i class="fa fa-paper-plane"></i
                              ></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card>
                </b-collapse>
              </div>

              <div class="col-12 text-center">
                <button
                  class="btn btn-dark"
                  @click.prevent="generatePDF()"
                  :disabled="loadingPDF"
                >
                  <b-spinner small v-if="loadingPDF"></b-spinner>
                  <span v-else
                    >Generate PDF <i class="fa fa-file-pdf"></i
                  ></span>
                </button>
              </div>
            </div>
          </form>
        </div>

        <div
          data-spy="affix"
          class="col-12 d-lg-none mt-5"
          v-if="!loadingReport"
        >
          <div class="sidenav-content shadow">
            <div class="widget-content widget-content-area" style="width: 100%">
              <div class="timeline-simple">
                <p class="timeline-title">Feedback</p>

                <div class="timeline-list">
                  <div
                    class="timeline-post-content"
                    v-for="feed in globalFeedbacks"
                    :key="feed.id"
                  >
                    <div class="user-profile">
                      <img :src="feed.user.image" alt="" />
                    </div>
                    <div class="">
                      <h4>
                        {{ feed.user.firstname }} {{ feed.user.lastname }}
                      </h4>
                      <small class="meta-time-date">
                        {{ formatFancyDate(feed.created_at) }}
                      </small>
                      <div class="">
                        <p class="post-text p-0 mr-5">
                          {{ feed.content }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="mt-3 text-center" v-if="loadingFeedback.global">
                    <b-card class="">
                      <b-skeleton animation="wave" width="85%"></b-skeleton>
                      <b-skeleton animation="wave" width="67%"></b-skeleton>
                      <b-skeleton animation="wave" width="70%"></b-skeleton>
                      <b-skeleton animation="wave" width="82%"></b-skeleton>
                      <b-skeleton animation="wave" width="65%"></b-skeleton>
                    </b-card>
                  </div>

                  <div class="form-group pr-2 mt-5">
                    <label for="">Add New Feedback</label>
                    <Mentionable :keys="['@']" :items="users" offset="3">
                      <textarea
                        class="form-control"
                        rows="3"
                        v-model="newFeedback.global"
                      />
                    </Mentionable>
                  </div>

                  <div class="col-12 text-center mt-3">
                    <button
                      type="button"
                      @click.prevent="saveFeedback()"
                      class="btn btn-primary"
                      :disabled="loadingFeedback.global"
                    >
                      <b-spinner
                        small
                        v-if="loadingFeedback.global"
                      ></b-spinner>
                      <span v-else>
                        Save Feedback <i class="fa fa-paper-plane"></i
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { endpoints } from "@/utils/endpoints";
import { http } from "@/utils/http";
import { Mentionable } from "vue-mention";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "ViewTask",

  components: {
    Mentionable,
  },

  computed: {
    ...mapGetters(["user", "url"]),

    score: function () {
      return (
        Math.round(this.routineScore) +
        Math.round(this.newScore) +
        Math.round(this.otherScore) +
        Math.round(this.outstandingScore)
      );
    },

    //['routine', 'outstanding', 'new', 'other', NULL]
    routineFeedbacks: function () {
      return this.feedbacks.filter((x) => x.type == "routine");
    },

    outstandingFeedbacks: function () {
      return this.feedbacks.filter((x) => x.type == "outstanding");
    },

    newFeedbacks: function () {
      return this.feedbacks.filter((x) => x.type == "new");
    },

    otherFeedbacks: function () {
      return this.feedbacks.filter((x) => x.type == "other");
    },

    weeklyreportFeedbacks: function () {
      return this.feedbacks.filter((x) => x.type == "weeklyreport");
    },

    paymentlogFeedbacks: function () {
      return this.feedbacks.filter((x) => x.type == "paymentlog");
    },

    outstandingvisitFeedbacks: function () {
      return this.feedbacks.filter((x) => x.type == "outstandingvisit");
    },

    corporatefirstvisitsFeedbacks: function () {
      return this.feedbacks.filter((x) => x.type == "corporatefirstvisits");
    },

    elderlyremarkFeedbacks: function () {
      return this.feedbacks.filter((x) => x.type == "elderlyremark");
    },

    didnotgowellFeedbacks: function () {
      return this.feedbacks.filter((x) => x.type == "didnotgowell");
    },

    prevweekFeedbacks: function () {
      return this.feedbacks.filter((x) => x.type == "prevweek");
    },

    suggestionFeedbacks: function () {
      return this.feedbacks.filter((x) => x.type == "suggestion");
    },

    globalFeedbacks: function () {
      return this.feedbacks.filter((x) => x.type == null);
    },
  },

  data() {
    return {
      loadingReport: false,
      loading: false,
      loadingPDF: false,

      routineTasks: [],
      routineScore: 0,

      newTasks: [],
      newScore: 0,

      otherTasks: [],
      otherScore: 0,

      outstandingTasks: [],
      outstandingScore: 0,

      date: null,
      prevScore: null,

      id: null,

      report: {
        user: {
          firstname: "",
          lastname: "",
          role: "",
        },
      },

      feedbacks: [],
      newFeedback: {
        routine: "",
        outstanding: "",
        new: "",
        other: "",
        didnotgowell: "",
        prevweek: "",
        suggestion: "",
        global: "",
        report: "", //handles all miscellenous reports for depts
      },
      loadingFeedback: {
        routine: false,
        outstanding: false,
        new: false,
        other: false,
        didnotgowell: false,
        prevweek: false,
        suggestion: false,
        global: false,
        report: false, //handles all miscellenous reports for depts
      },

      users: [],
    };
  },

  mounted() {
    this.id = this.$route.params.id;
    this.fetchReport();
  },

  methods: {
    fetchReport() {
      this.loadingReport = true;
      http
        .get(endpoints.SINGLE_TASK.replace(":id", this.id))
        .then((response) => {
          this.report = response;

          this.routineTasks = this.report.items.filter((x) => {
            return x.type == "routine";
          });

          this.newTasks = this.report.items.filter((x) => {
            return x.type == "new";
          });

          this.otherTasks = this.report.items.filter((x) => {
            return x.type == "other";
          });

          this.outstandingTasks = this.report.items.filter((x) => {
            return x.type == "outstanding";
          });

          response.users.forEach((x) => {
            var holder = {};
            holder.value = `${x.firstname}`;
            holder.label = `${x.firstname} ${x.lastname}`;
            this.users.push(holder);
          });

          this.feedbacks = this.report.feedbacks;

          this.loadingReport = false;
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    saveFeedback(type = "NULL") {
      if (Object.values(this.newFeedback).every((x) => x == "")) {
        return this.$toast.error("Feedback cannot be empty");
      }

      var temp = null;

      switch (type) {
        case "routine":
          temp = this.newFeedback.routine;
          this.newFeedback.routine = "";
          this.loadingFeedback.routine = true;
          break;
        case "outstanding":
          temp = this.newFeedback.outstanding;
          this.newFeedback.outstanding = "";
          this.loadingFeedback.outstanding = true;
          break;
        case "new":
          temp = this.newFeedback.new;
          this.newFeedback.new = "";
          this.loadingFeedback.new = true;
          break;
        case "other":
          temp = this.newFeedback.other;
          this.newFeedback.other = "";
          this.loadingFeedback.other = true;
          break;
        case "didnotgowell":
          temp = this.newFeedback.didnotgowell;
          this.newFeedback.didnotgowell = "";
          this.loadingFeedback.didnotgowell = true;
          break;
        case "prevweek":
          temp = this.newFeedback.prevweek;
          this.newFeedback.prevweek = "";
          this.loadingFeedback.prevweek = true;
          break;
        case "suggestion":
          temp = this.newFeedback.suggestion;
          this.newFeedback.suggestion = "";
          this.loadingFeedback.suggestion = true;
          break;
        case "NULL":
          temp = this.newFeedback.global;
          this.newFeedback.global = "";
          this.loadingFeedback.global = true;
          break;
        default:
          temp = this.newFeedback.report;
          this.newFeedback.report = "";
          this.loadingFeedback.report = true;
          break;
      }
      http
        .post(endpoints.SAVE_NEW_TASK_FEEDBACK, {
          task_id: this.id,
          content: temp,
          type,
        })
        .then((response) => {
          this.$toast.success("Feedback added successfully");
          this.feedbacks.push(response);
          this.loadingFeedback = _.mapValues(this.loadingFeedback, () => false);
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
          this.loadingFeedback = _.mapValues(this.loadingFeedback, () => false);
        });
    },

    userWasMentioned(type) {
      var result = false;

      if (
        this.user.role == "management" ||
        this.user.role == "admin" ||
        this.report.user_id == this.user.id
      ) {
        result = true;
      } else {
        switch (type) {
          case "routine":
            this.routineFeedbacks.forEach((feed) => {
              if (feed.content.includes(this.user.firstname)) {
                result = true;
              }
            });
            break;
          case "outstanding":
            this.outstandingFeedbacks.forEach((feed) => {
              if (feed.content.includes(this.user.firstname)) {
                result = true;
              }
            });
            break;
          case "new":
            this.newFeedbacks.forEach((feed) => {
              if (feed.content.includes(this.user.firstname)) {
                result = true;
              }
            });
            break;
          case "other":
            this.otherFeedbacks.forEach((feed) => {
              if (feed.content.includes(this.user.firstname)) {
                result = true;
              }
            });
            break;
          case "didnotgowell":
            this.didnotgowellFeedbacks.forEach((feed) => {
              if (feed.content.includes(this.user.firstname)) {
                result = true;
              }
            });
            break;
          case "prevweek":
            this.prevweekFeedbacks.forEach((feed) => {
              if (feed.content.includes(this.user.firstname)) {
                result = true;
              }
            });
            break;
          case "suggestion":
            this.suggestionFeedbacks.forEach((feed) => {
              if (feed.content.includes(this.user.firstname)) {
                result = true;
              }
            });
            break;

          default:
            result = false;
            break;
        }
      }

      return result;
    },

    generatePDF() {
      this.loadingPDF = true;
      http
        .get(endpoints.GENERATE_REPORT_PDF.replace(":id", this.report.id))
        .then(() => {
          this.loadingPDF = false;
          this.$toast.info("The pdf is on its way to your mailbox");
        })
        .catch((error) => {
          this.loadingPDF = false;
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
  },
};
</script>

<style scoped src="@/assets/css/forms/theme-checkbox-radio.css"></style>
<style scoped src="@/assets/css/forms/switches.css"></style>
<style>
.fab-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  user-select: none;
  position: sticky;
  bottom: 30px;
  right: 30px;
  pointer-events: none;
}

.fab-container .fab {
  position: relative;
  height: 90px;
  width: 90px;
  background-color: #28a745;
  border-radius: 50%;
  z-index: 9999;
  pointer-events: none;
}
.fab-container .fab::before {
  content: " ";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 35px;
  width: 35px;
  background-color: inherit;
  border-radius: 0 0 10px 0;
  z-index: -1;
  pointer-events: none;
}
.fab-container .fab .fab-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;
  font-weight: bolder;
  font-size: 20px;
  width: 100%;
  border-radius: 50%;
  pointer-events: none;
}

/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
.sidenav {
  position: fixed;
  right: -30px;
  top: 125px;
  width: 396px;
  height: auto;
  border-left: 1px solid #e0e6ed;
}
.sidenav .sidenav-header:after {
  display: none;
}
.sidenav .sidenav-header p {
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  margin-bottom: 20px;
  background: #049a58;
  text-align: center;
  border-radius: 5px;
  padding: 4px;
  letter-spacing: 1px;
  background-image: linear-gradient(to right, #049a58 0%, #5c1ac3 100%);
}
.sidenav .sidenav-content {
  background-color: transparent;
  display: block;
  border: none;
}
.sidenav .sidenav-content a {
  display: block;
  padding: 3px 0px;
  color: #3b3f5c;
  font-size: 12px;
  padding: 3px 25px;
}
.sidenav .sidenav-content a.active {
  color: #5c1ac3;
  font-weight: 700;
  border-left: 1px solid #5c1ac3;
}
.sidenav .sidenav-content a:hover {
  color: #049a58;
  font-weight: 700;
  border-left: 1px solid #5c1ac3;
}

#content > .container {
  display: flex;
  max-width: 58.333333% !important;
  margin-left: 2px;
  padding: 0 16px !important;
}
#content > .container > .container {
  padding: 0;
  margin: 0;
}

@media (max-width: 575px) {
  .sidenav .sidenav-content a {
    padding: 4px 7px;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

@media (max-width: 1199px) {
  .sidenav {
    display: none;
  }
  #content > .container {
    max-width: 100% !important;
    margin-left: auto;
  }
  #content > .container > .container {
    max-width: 100%;
  }
}
</style>

<style
  scoped
  src="@/assets/css/components/timeline/custom-timeline.css"
></style>
<style>
.new-control {
  font-weight: 400;
  font-size: 15px;
}
.tableInput {
  border: none !important;
  font-size: 14px;
}

.mention-item {
  padding: 4px 10px !important;
  border-radius: 4px !important;
}

.mention-selected {
  background: rgb(192, 250, 153) !important;
}

.tooltip {
  display: block !important;
  z-index: 1000000000000000000000000000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

.v-tooltip-open {
  z-index: 999999999999999999999999999;
}
</style>

<style>
.mention-item {
  padding: 4px 10px !important;
  border-radius: 4px !important;
}

.mention-selected {
  background: #049a58 !important;
  color: white;
}

.tooltip {
  display: block !important;
  z-index: 1000000000000000000000000000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

.v-tooltip-open {
  z-index: 999999999999999999999999999;
}
</style>
